












































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import STLViewer from '@/components/util/STLViewer.vue';
import B64ImageViewer from '@/components/util/ImageViewer.vue';
import {
  CountersinkInfo,
  createNewPartEntry,
  CustomerPartEntry,
  ThicknessInfo,
  ThreadInfo
} from '@/customer/part_details';
import MaterialSelect from '@/components/calculator/MaterialSelect.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import UploadCardGeneral from '@/components/util/UploadCardGeneral.vue';
import { AdditionalPartFile, PartEntry } from '@/store';

@Component({
  components: {
    za3dvisualizer: STLViewer,
    za2dvisualizer: B64ImageViewer,
    materialSelect: MaterialSelect,
    zaUpload: UploadCardGeneral
  }
})
export default class CalcPropertiesItem extends Vue {
  @Prop({ type: Object, required: true })
  part!: any;

  visualizer: string = 'za2dvisualizer';
  visualizerData = { uuid: this.part.part_uuid };

  selectedMaterial: string = this.part.details.material ? this.part.details.material : '';
  thickness: number = this.part.details.thickness ? this.part.details.thickness : 0;
  drills: any[] = this.part.details.drills ? this.part.details.drills : [];
  currFiles: AdditionalPartFile[] = this.part.additional_files;

  acceptedFiles: string[] = ['.pdf', '.png', '.jpg', '.jpeg', '.stl', '.stp', '.step', '.dxf', '.dwg', '.tif', '.bmp'];
  defaultMessage: string = 'Hochzuladende Dateien hier reinziehen oder mit Klick Dialog öffnen';

  getColorAccordingToType() {
    return 'primary';
  }

  noteChanged(value: string) {
    this.changeProperty(this.part.part_uuid, 'note', value);
  }

  addSet() {
    axios
      .post(`${BACKEND_URL}/add_additional_set`, {
        offer_id: this.$store.getters.currentOfferID,
        part_uuid: this.part.part_uuid
      })
      .then(response => {
        this.$store.commit('addSetEntry', {
          setUUID: Object.keys(response.data).pop(),
          setEntry: Object.values(response.data).pop()
        });
      });
  }

  deleteLastSet() {
    let last = this.countsOfPart.pop();
    axios
      .post(`${BACKEND_URL}/delete_set`, {
        offer_id: this.$store.getters.currentOfferID,
        set_uuid: last['set_uuid']
      })
      .then(response => {
        this.$store.commit('deleteSetEntry', {
          setUUID: last['set_uuid']
        });
      });
  }

  deletePart(part_uuid: string) {
    this.$store.dispatch('deletePart', part_uuid);
  }

  deleteAddFile(add_part: AdditionalPartFile) {
    this.$store.dispatch('deleteFile', add_part.uuid);
  }

  numSetChanged(count: any, setUUID: any, ind: any) {
    axios
      .post(`${BACKEND_URL}/update_set_count`, {
        offer_id: this.$store.getters.currentOfferID,
        part_uuid: this.part.part_uuid,
        count: ind == 0 ? Math.max(count, 1) : count,
        set_uuid: setUUID
      })
      .then(response => {
        this.$store.commit('setOffer', response.data.offer);
        this.$store.commit('addSetEntry', {
          setUUID: Object.keys(response.data.set).pop(),
          setEntry: Object.values(response.data.set).pop()
        });
      });
  }

  materialChanged(material: any) {
    this.selectedMaterial = material;
    this.changeProperty(this.part.part_uuid, 'material', this.selectedMaterial);
  }

  thicknessChanged(thickness: any) {
    this.thickness = thickness;
    this.changeProperty(this.part.part_uuid, 'thickness', this.thickness);
  }

  // informationChanged(selection: any) {
  //   this.selectedMaterial = selection.material;
  //   this.thickness = selection.thickness;
  //   this.updatePartInformation();
  // }

  addDrill(drill: any) {
    this.drills.push(drill);
    this.changeProperty(this.part.part_uuid, 'drills', this.drills);
  }

  deleteDrill(index: number) {
    this.drills.splice(index, 1);
    this.changeProperty(this.part.part_uuid, 'drills', this.drills);
  }

  changeProperty(part_uuid: any, name: any, value: any) {
    this.$store.dispatch('updatePartDetail', { part_uuid: part_uuid, name: name, value: value });
  }

  onFileWrongExt(file: File) {}

  onFilesAdded(files: File[]) {
    for (const file of files) {
      this.uploadFile(file);
    }
  }

  uploadFile(file: any) {
    for (let entry of this.currFiles) {
      if (entry.name === file.name) {
        return;
      }
    }
    if (this.$store.getters.containsFileName(file.name)) {
      return;
    }
    const formData = new FormData();
    formData.set('file', file);
    formData.set('offer_id', this.$store.getters.currentOfferID);
    formData.set('part_uuid', this.part.part_uuid);
    let file_entry: AdditionalPartFile = {
      name: file.name,
      size: 0,
      type: '',
      uuid: '',
      progress: 0
    };
    this.currFiles.push(file_entry);
    axios
      .post(`${BACKEND_URL}/upload/additional`, formData, {
        onUploadProgress: event => this.onUploadProgress(file_entry, event)
      })
      .then(value => {
        file_entry.name = value.data['file_name'];
        file_entry.size = value.data['size'];
        file_entry.type = value.data['ext'];
        file_entry.uuid = value.data['uuid'];
        file_entry.progress = value.data['progress'];
      })
      .catch(() => {
        this.$store.commit('deleteFile', file.name);
      });
  }

  placeholder() {}

  onUploadProgress(file: AdditionalPartFile, progressEvent: ProgressEvent) {
    file.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  }

  get countsOfPart() {
    return this.$store.getters.getSetCountsOfPartUUID(this.part.part_uuid);
  }
}

<template>
  <!--  <v-lazy-->
  <!--    v-model="isActive"-->
  <!--    :options="{-->
  <!--      threshold: 0.5-->
  <!--    }"-->
  <!--    min-height="200"-->
  <!--    transition="fade-transition"-->
  <!--  >-->
  <v-card>
    <v-card-title>
      <div>
        <v-text-field
          label="Bezeichung"
          :value="part.details.name"
          @change="changeProperty(part.part_uuid, 'name', $event)"
          :style="part.details.name_modified ? 'background-color: aqua' : ''"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn @click="resetPart(part.part_uuid)" fab text small>
          <v-icon>
            refresh
          </v-icon>
        </v-btn>
      </div>
      <div>
        <v-btn @click="deletePart(part.part_uuid)" fab text small>
          <v-icon>
            delete
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col :style="part.details.manufacturing_modified ? 'background-color: aqua' : ''">
          <v-radio-group
            v-model="part.details.manufacturing"
            @change="changeProperty(part.part_uuid, 'manufacturing', $event)"
            row
          >
            <v-radio
              v-for="t in Object.keys(manufacturerTechnology)"
              :key="`${t}_${part.part_uuid}`"
              :label="t"
              :value="t"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="5" :style="part.details.form_modified ? 'background-color: aqua' : ''">
          <v-select
            label="Form"
            :items="manufacturerTechnology[part.details.manufacturing]"
            :value="part.details.form"
            @change="changeProperty(part.part_uuid, 'form', $event)"
          ></v-select>
        </v-col>

        <!-- STYLING WAS NEEDED TO KEEP CANVAS FROM GROWING -->
        <v-col cols="12" class="mx" style="position:relative; height: 250px">
          <div style="position:absolute; top:0; bottom: 0; width:90%;">
            <div class="fillParent" ref="render"></div>
          </div>
          <!--<span style="height:100px" ref="render"></span>-->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <!--  </v-lazy>-->
</template>

<script>
export default {
  name: 'CalcTileCard',
  props: ['part'],
  data() {
    return {
      manufacturerTechnology: {
        Flachbettlaser: ['Flach', 'Gebogen'],
        Rohrlaser: ['Rundrohr', 'Rechteckrohr', 'Quadratrohr', 'L-Profil', 'U-Profil', 'H-Profil', 'Flach'],
        Übrige: ['Übrige'],
      },
      isActive: false,
    }
  },
  methods: {
    changeProperty(part_uuid, name, value) {
      let upObj = {}
      upObj[name] = value
      // if (!this.manufacturerTechnology[this.part.details.manufacturing].includes(this.part.details.form)) {
      //   this.part.details.form = this.manufacturerTechnology[this.part.details.manufacturing][0]
      //   if (name !== 'form') {
      //     upObj['form'] = this.part.details.form
      //   }
      // }
      this.$store.dispatch('updatePartDetailWithObject', { part_uuid: part_uuid, update_obj: upObj })
    },
    deletePart(part_uuid) {
      this.$store.dispatch('deletePart', part_uuid)
    },
    resetPart(part_uuid) {
      this.$store.dispatch('resetPartDetail', part_uuid)
    },
  },
  watch: {
    isActive: function(val) {
      if (val) {
      }
    },
  },
  mounted() {
    this.$emit('item-created', this.part, this.$refs.render)
  },
  destroyed() {
    this.$emit('item-deleted', this.part, this.$refs.render)
  },
}
</script>

<style scoped></style>

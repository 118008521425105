<template>
  <v-img :id="visualizerData.uuid" :src="imageSrc" contain></v-img>
</template>

<script>
import { BACKEND_URL } from '@/za_conf';
import Vue from 'vue';

export default Vue.extend({
  props: {
    visualizerData: Object
  },
  // type inference enabled
  computed: {
    imageSrc() {
      return `${BACKEND_URL}/part/img/${this.visualizerData.uuid}`;
    }
  }
});
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Angebot")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12"}},[(!_vm.isVerified)?_c('v-btn',{on:{"click":_vm.verify}},[_vm._v("Anfrage einreichen")]):_vm._e()],1),_c('v-col',{staticClass:"ma-0 pa-0 d-flex justify-end align-end text-end",attrs:{"cols":"12"}},[_c('orderdialog',{attrs:{"offerId":_vm.offerId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var action = ref.action;
return [_c('v-btn',{on:{"click":action}},[_c('v-icon',[_vm._v("shopping_cart")]),_vm._v(" "+_vm._s(_vm.buttonName(_vm.isVerified))+" ")],1)]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',[_c('v-row',[(!_vm.isVerified)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Leider war eine automatische Berechnung dieser Anfrage nicht möglich. Sie haben die Möglichkeit die Anfrage für eine manuelle Prüfung einzureichen oder direkt zu bestellen. ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.partsWithSets,"item-key":"name","disable-pagination":"","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var ref_item = ref.item;
var group_key = ref_item.group_key;
var parts = ref_item.parts;
var sets = ref_item.sets;
return [_c('tr',[_c('td',{staticStyle:{"text-align":"left","font-weight":"bold"}},[_vm._v(_vm._s(_vm.getPartNameByUUID(parts[0])))]),_vm._l((sets),function(setUUID,index){return [_c('td',{key:(group_key + "td_los" + index)},[_vm._v(_vm._s(_vm.getCountOfSetForPart(parts[0], setUUID)))]),(_vm.getSetByUUID(setUUID).calculation.total_correct)?_c('td',{key:(group_key + "td_los_cost" + index)},[_vm._v(" "+_vm._s(_vm.getSetByUUID(setUUID).calculation.total_correct)+" CHF ")]):_c('td',{key:(group_key + "td_los_cost" + index)},[_vm._v(" manuelle Prüfung ")])]}),_vm._l((_vm.maxNumberOfSetsInGroup - sets.length),function(_,index){return _c('td',{key:(group_key + "td_los_filler" + index),attrs:{"colspan":"2"}})})],2)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
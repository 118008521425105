

























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NavigationDrawer extends Vue {
  navigate(target: string) {
    this.$router.push({ name: target })
  }

  get hasManufacturerRights() {
    return this.$store.getters.hasManufacturerRights
  }
}











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BACKEND_URL } from '@/za_conf'
import axios from 'axios'

export default Vue.extend({
  data() {
    return {
      dialog: false,
      layers: [],
    }
  },
  props: {
    uuid: String,
    name: String,
  },
  methods: {
    deletePart() {
      this.dialog = false
      this.$store.dispatch('deletePart', this.name)
    },
  },
  mounted(): void {
    axios
      .get(`${BACKEND_URL}/layers/${this.uuid}`)
      .then(response => {
        this.layers = response.data['layer_data']['layers']
        if (this.layers.length == 1) {
        }
      })
      .catch(() => {})
  },
})















import { Component, Vue } from 'vue-property-decorator'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'

@Component({
  components: {
    zaHeader: Header,
    zaFooter: Footer,
  },
})
export default class App extends Vue {}

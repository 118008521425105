<template>
  <v-footer height="auto" color="#404040" width="100%" padless app>
    <v-row justify="center" no-gutters>
      <v-col class="text-center white--text" style="background-color: #404040" cols="12">
        &copy;{{ new Date().getFullYear() }} — <strong>Synapto GmbH</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer.vue',
}
</script>


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CalcFileUpload from '../components/calculator/CalcFileUpload.vue';
import CalcProperties from '@/components/calculator/CalcProperties.vue';
import CalcOffer from '@/components/calculator/CalcOffer.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcShipping from '@/components/calculator/CalcShipping.vue';
import CalcOrder from '@/components/calculator/CalcOrder.vue';
import CalcExportLastech from '@/components/calculator/CalcExportLastech.vue';
import CalcParamPrototypeCost from '@/components/calculator/CalcParamPrototypeCost.vue';
import CalcRodOptimization from '@/components/calculator/CalcRodOptimization.vue';
import CalcTileView from '@/components/calculator/CalcTileView.vue';
import CalcParamPrototypeFlatbedLaser from '@/components/calculator/CalcParamPrototypeFlatbedLaser.vue';
import CalcParamPrototypeTubeLaser from '@/components/calculator/CalcParamPrototypeTubeLaser.vue';
import CalcParamPrototypeUnknown from '@/components/calculator/CalcParamPrototypeUnknown.vue';

interface SelectedCustomersTypes {
  customer_id: number;
  sub_customer_id: number;
}

@Component({
  components: {
    calcfileupload: CalcFileUpload,
    calcproperties: CalcProperties,
    calcoffer: CalcOffer,
    calcshipping: CalcShipping,
    calcorder: CalcOrder,
    calcexport: CalcExportLastech,
    calcparamprototypeflat: CalcParamPrototypeFlatbedLaser,
    calcparamprototypetube: CalcParamPrototypeTubeLaser,
    calcparamprototypeunknown: CalcParamPrototypeUnknown,
    calcparamprototypecost: CalcParamPrototypeCost,
    calcrodoptimization: CalcRodOptimization,
    calctileview: CalcTileView
  }
})
export default class Calculator extends Vue {
  offer_name: string = '';
  steps = this.defineSteps();
  internOffer: boolean = false;

  defineSteps() {
    let vm = this;

    let steps = [];

    steps.push({
      name: 'Dateien hochladen',
      component: 'calcfileupload',
      eventHandling: {}
    });
    steps.push({
      name: 'Bauteile',
      component: 'calctileview',
      eventHandling: {}
    });
    steps.push({
      name: 'Flachbettlaser',
      component: 'calcparamprototypeflat',
      eventHandling: {}
    });
    steps.push({
      name: 'Rohrlaser',
      component: 'calcparamprototypetube',
      eventHandling: {}
    });
    steps.push({
      name: 'Übrige',
      component: 'calcparamprototypeunknown',
      eventHandling: {}
    });
    // if (this.hasManufacturerRights()) {
    //   steps.push({
    //     name: 'Kostenübersicht',
    //     component: 'calcparamprototypecost',
    //     eventHandling: {}
    //   });
    // }
    steps.push({
      name: 'Export',
      component: 'calcrodoptimization',
      eventHandling: {}
    });

    return steps;
  }

  backToOfferOverview() {
    this.$store.commit('resetOffer');
    this.$router.push({ path: '/' });
  }

  deleteOffer() {
    this.$store.dispatch('deleteCurrentOffer');
    this.$store.commit('resetOffer');
  }

  get currentOfferName() {
    return this.$store.getters.getFormattedOfferId + ' - ' + this.$store.getters.currentOfferName;
  }

  get currentStep() {
    return this.$store.getters.currentStepCalcDialog;
  }

  calcOffset() {
    let calc_container: Element = this.$refs.calc_container as Element;
    return calc_container.getBoundingClientRect().left;
  }

  updateStep(step_number: number) {
    this.$store.commit('setCurrentStepCalcDialog', step_number);
  }

  get isOfferActive() {
    return this.$store.getters.isOfferActive;
  }

  createOffer() {
    axios
      .post(`${BACKEND_URL}/create_offer`, {
        offer_name: this.offer_name,
        is_intern: this.internOffer
      })
      .then(response => {
        if (response.status === 200) {
          if ('offer' in response.data) {
            this.$store.commit('setOffer', response.data.offer);
          }
        }
      });
  }

  isCustomer() {
    return this.$store.getters.isCustomer;
  }

  hasManufacturerRights() {
    return this.$store.getters.hasManufacturerRights;
  }
}










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MaterialInfo, ThicknessInfo } from '@/customer/part_details';

export interface MatThick {
  material_name: string;
  thickness_info: ThicknessInfo;
}

@Component
export default class MaterialSelect extends Vue {
  @Prop({ type: String, default: '' })
  material: any;

  @Prop({ type: Object, default: () => new ThicknessInfo() })
  thickness_info!: ThicknessInfo;

  local_material_info: any = {
    material: this.material,
    on_request: this.thickness_info.on_request
  };
  local_material: string = this.material;
  local_thickness: string = this.getCorrectStringNum(
    this.thickness_info.thickness
  );

  getCorrectStringNum(number: any) {
    let val = Number(number);
    let res: any;
    if (String(number).split('.').length == 1) {
      res = val.toFixed(1);
    } else {
      res = String(number);
    }
    return res;
  }

  thicknessChanged(thickness: number) {
    this.local_thickness = this.getCorrectStringNum(thickness);
    let needSet = true;
    for (let t of this.processedMaterials[this.local_thickness]) {
      if (this.local_material && t.material == this.local_material) {
        this.local_material = t.material;
        this.local_material_info = t;
        needSet = false;
        break;
      }
    }
    if (needSet) {
      this.local_material_info = new MaterialInfo();
      this.local_material = '';
    }
    this.emitInformationChange();
  }

  materialChanged(material_info: MaterialInfo) {
    this.local_material_info = material_info;
    this.local_material = material_info.material;
    this.emitInformationChange();
  }

  emitInformationChange() {
    let data: MatThick = {
      material_name: this.local_material,
      thickness_info: {
        thickness: Number(this.local_thickness),
        on_request: this.local_material_info.on_request
      }
    };
    this.$emit('informationChanged', data);
  }

  get processedMaterials() {
    return this.$store.getters.processedMaterials;
  }

  mounted() {}
}

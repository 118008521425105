
































import { Component, Vue } from 'vue-property-decorator';
import CalcManualDialogGeometry from '@/components/calculator/CalcManualDialogGeometry.vue';
import { createNewPartEntry, CustomerPartEntry } from '@/customer/part_details';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';

@Component
export default class BlankoCardDialog extends Vue {
  valid = false;
  numBlankoPositions = 1;
  numBlankoPositionsRules = [(v: number) => v > 0 || 'Anzahl >= 1'];

  initData() {
    this.valid = false;
    this.numBlankoPositions = 1;
  }

  reset() {
    this.initData();
    this.form.resetValidation();
    this.form.reset();
  }

  deactivateDialog() {
    this.reset();
    this.$emit('close-dialog');
  }

  addBlankoPositions() {
    // TODO is serialization of geometries and bends needed?
    //  Maybe just calculate values needed for formula here and send result!
    if (this.form.validate()) {
      axios
        .post(`${BACKEND_URL}/add_blanko`, {
          num_blanko_positions: this.numBlankoPositions,
          offer_id: this.$store.getters.currentOfferID
        })
        .then(value => {
          let uploaded_file = value.data['file'];
          this.$store.commit('addFileEntry', {
            fileUUID: uploaded_file['file_uuid'],
            fileEntry: { expanded: false, ...uploaded_file }
          });
          let sets = value.data['sets'];
          for (let currSet of sets) {
            this.$store.commit('addSetEntry', {
              setUUID: currSet['set_uuid'],
              setEntry: currSet
            });
          }
          let parts = value.data['parts'];
          for (let currPart of parts) {
            this.$store.commit('addPartEntry', {
              partUUID: currPart['part_uuid'],
              partEntry: currPart
            });
          }
        });

      this.reset();
      this.$emit('close-dialog');
    }
  }

  get form(): Vue & {
    validate: () => boolean;
    reset: () => boolean;
    resetValidation: () => boolean;
  } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => boolean;
      resetValidation: () => boolean;
    };
  }
}

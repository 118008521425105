<template>
  <div style="position:absolute; top:0; bottom: 0; width:100%;">
    <div class="fillParent" ref="render"></div>
  </div>
  <!--<span style="height:100px" ref="render"></span>-->
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    visualizerData: Object
  },
  // type inference enabled
  data() {},
  mounted() {
    this.$parent.$emit(
      'item-created',
      this.visualizerData.part,
      this.$refs.render
    );
  },
  destroyed() {
    this.$parent.$emit(
      'item-deleted',
      this.visualizerData.part,
      this.$refs.render
    );
  }
});
</script>

<style scoped></style>

<template>
  <div>
    Kundenangaben für Offerte
    <v-radio-group v-model="searchCategory" row>
      <v-radio
        v-for="(value, key) in categories"
        :key="key"
        :label="value.label"
        :value="key"
      ></v-radio>
    </v-radio-group>

    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="customers"
      :search-input.sync="search"
      cache-items
      hide-details
      hide-no-data
      hide-selected
      :item-text="extractText"
      :item-value="extractValue"
      :label="categories[searchCategory].display"
      solo-inverted
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <strong>
            <v-list-item-title
              v-text="item.customer_number"
            ></v-list-item-title>
          </strong>
          <v-list-item-title v-text="item.name_1"></v-list-item-title>
          <v-list-item-subtitle
            v-text="displayName(item)"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <div>
      <v-text-field
        label="Kunden-Nummer"
        v-model="customer_number"
      ></v-text-field>
      <v-text-field label="Firmen-Namen" v-model="company_name"></v-text-field>
      <v-text-field label="Vorname" v-model="person_forename"></v-text-field>
      <v-text-field label="Nachnamen" v-model="person_surname"></v-text-field>
      <v-text-field label="Adresse" v-model="address"></v-text-field>
      <v-text-field label="Stadt" v-model="city"></v-text-field>
      <v-text-field label="PLZ" v-model="plz"></v-text-field>
    </div>
    <div>
      <v-btn class="green" @click="verifyOffer"
        >Fertigstellen / Verifizieren</v-btn
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default Vue.extend({
  // type inference enabled
  data() {
    return {
      customer_number: null,
      company_name: null,
      person_forename: null,
      person_surname: null,
      address: null,
      plz: null,
      city: null,
      loading: false,
      search: null,
      select: null,
      customers: [],
      categories: {
        customer_nr: {
          label: 'Kunden-Nummer',
          display: 'Nach Kunden-Nummer suchen'
        },
        company_name: {
          label: 'Firmen-Namen',
          display: 'Nach Firmen-Namen suchen'
        },
        person_name: {
          label: 'Personen-Namen',
          display: 'Nach Personen-Namen suchen'
        }
      },
      searchCategory: 'customer_nr'
    };
  },
  methods: {
    verifyOffer() {
      axios
        .post(`${BACKEND_URL}/verify_offer`, {
          offer_id: this.$store.getters.currentOfferID,
          contact: {
            customer_number: this.customer_number,
            company_name: this.company_name,
            person_forename: this.person_forename,
            person_surname: this.person_surname,
            address: this.address,
            plz_city: this.plz + ' ' + this.city
          }
        })
        .then(response => {
          this.$store.commit('resetOffer');
          this.$router.push({ path: '/' });
        });
    },
    extractText(v) {
      switch (this.searchCategory) {
        case 'customer_nr':
          return v.customer_number;
          break;
        case 'company_name':
          return v.name_1;
          break;
        case 'person_name':
          return v.forename + ' ' + v.surname;
          break;
      }
    },
    extractValue(v) {
      return v;
    },
    displayName(item) {
      let forename = item.forename ? item.forename : '';
      let surname = item.surname ? item.surname : '';
      return forename + ' ' + surname;
    },
    querySelectionDebounced(v) {
      this.loading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.querySelections(v);
      }, 500);
    },
    querySelections(v) {
      axios
        .post(`${BACKEND_URL}/search_customer`, {
          search_category: this.searchCategory,
          search_str: v
        })
        .then(response => {
          if (response.status === 200) {
            this.customers = response.data.customers;
            this.loading = false;
          }
        })
        .catch(error => {});
    }
  },
  computed: {
    offerContact() {
      return this.$store.getters.currentOfferContact;
    }
  },
  watch: {
    offerContact(val) {
      if (val) {
        this.customer_number = val.customer_number;
        this.company_name = val.company_name;
        this.person_forename = val.person_forename;
        this.person_surname = val.person_surname;
        this.address = val.address;
        this.plz = val.plz_city.substr(0, val.plz_city.indexOf(' '));
        this.city = val.plz_city.substr(val.plz_city.indexOf(' ') + 1);
      }
    },
    search(val) {
      val &&
        val.length > 2 &&
        val !== this.select &&
        this.querySelectionDebounced(val);
    },
    searchCategory(val) {
      this.search = null;
      this.select = null;
      this.items = [];
    },
    select(val) {
      if (val) {
        this.customer_number = val.customer_number;
        this.company_name = val.name_1;
        this.person_forename = val.forename;
        this.person_surname = val.surname;
        this.address = val.street;
        this.plz = val.plz;
        this.city = val.city;
      }
    }
  },
  mounted() {
    if (this.offerContact) {
      this.customer_number = this.offerContact.customer_number;
      this.company_name = this.offerContact.company_name;
      this.person_forename = this.offerContact.person_forename;
      this.person_surname = this.offerContact.person_surname;
      this.address = this.offerContact.address;
      this.plz = this.offerContact.plz_city
        ? this.offerContact.plz_city.substr(
            0,
            this.offerContact.plz_city.indexOf(' ')
          )
        : '';
      this.city = this.offerContact.plz_city
        ? this.offerContact.plz_city.substr(
            this.offerContact.plz_city.indexOf(' ') + 1
          )
        : '';
    }
  }
});
</script>
<style scoped></style>

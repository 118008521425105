<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="selectedHeaders"
          :items="headers.filter(x => !x.isAction)"
          label="Angezeigte Spalten"
          @change="changeHeaders"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 5">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 5" class="grey--text text-caption">(+{{ selectedHeaders.length - 5 }} weitere)</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="6"></v-col>

      <v-col cols="8">
        Total Kosten:
        <div style="font-weight: bold">
          {{ items.reduce((a, b) => a + b.set.calculation.cost_cost_total, 0) | round(2) }} CHF
        </div>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="showHeaders"
          :items="items"
          class="elevation-1"
          group-by="source_file_uuid"
          :expanded.sync="expanded"
          item-key="set_uuid"
          disable-pagination
          hide-default-footer
          single-expand
        >
          <template v-slot:item="{ item, isExpanded, expand }">
            <tr :style="errorStyle(item.set.calculation)">
              <td v-if="displayColumn('name')">
                <v-text-field
                  :value="item.details.name"
                  @change="changeProperty(item.part_uuid, 'name', $event)"
                  :style="item.details.name_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('num_parts')">
                <v-text-field
                  type="number"
                  :value="item.set.parts[0].count"
                  @change="numSetChanged(Number($event), item.set.set_uuid, item.part_uuid, 0)"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('material')">
                <v-select
                  :items="materialItems"
                  :value="item.details.material ? item.details.material : materialItems[0]"
                  @change="changeProperty(item.part_uuid, 'material', $event)"
                  :style="item.details.material_modified ? 'background-color: aqua' : ''"
                ></v-select>
              </td>
              <td v-if="displayColumn('share_operator')">
                <v-text-field
                  type="number"
                  :value="item.details.share_operator"
                  @change="changeProperty(item.part_uuid, 'share_operator', Number($event))"
                  :style="item.details.share_operator_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('complexity_programming')">
                <v-select
                  :items="programmingItems"
                  :value="item.details.complexity_programming"
                  @change="changeProperty(item.part_uuid, 'complexity_programming', $event)"
                  :style="item.details.complexity_programming_modified ? 'background-color: aqua' : ''"
                ></v-select>
              </td>
              <td v-if="displayColumn('cost_time_tube_laser_seconds')">
                <v-text-field
                  type="number"
                  :value="(item.set.calculation.cost_time_tube_laser_seconds / 60) | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_time_tube_laser_seconds', Number($event) * 60)"
                  :style="item.set.calculation.cost_time_tube_laser_seconds_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_tube_laser_cost_per_part')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_tube_laser_cost_per_part | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_tube_laser_cost_per_part', Number($event))"
                  :style="item.set.calculation.cost_tube_laser_cost_per_part_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_tube_laser_cost_total')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_tube_laser_cost_total | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_tube_laser_cost_total', Number($event))"
                  :style="item.set.calculation.cost_tube_laser_cost_total_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_gas_cost_per_part')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_gas_cost_per_part | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_gas_cost_per_part', Number($event))"
                  :style="item.set.calculation.cost_gas_cost_per_part_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_gas_cost_total')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_gas_cost_total | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_gas_cost_total', Number($event))"
                  :style="item.set.calculation.cost_gas_cost_total_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_time_operator_seconds')">
                <v-text-field
                  type="number"
                  :value="(item.set.calculation.cost_time_operator_seconds / 60) | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_time_operator_seconds', Number($event) * 60)"
                  :style="item.set.calculation.cost_time_operator_seconds_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_operator_cost_total')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_operator_cost_total | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_operator_cost_total', Number($event))"
                  :style="item.set.calculation.cost_operator_cost_total_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_time_programming_seconds')">
                <v-text-field
                  type="number"
                  :value="(item.set.calculation.cost_time_programming_seconds / 60) | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_time_programming_seconds', Number($event) * 60)"
                  :style="item.set.calculation.cost_time_programming_seconds_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_programming_cost_total')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_programming_cost_total | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_programming_cost_total', Number($event))"
                  :style="item.set.calculation.cost_programming_cost_total_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_cost_per_part')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_cost_per_part | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_cost_per_part', Number($event))"
                  :style="item.set.calculation.cost_cost_per_part_modified ? 'background-color: aqua' : ''"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cost_cost_total')">
                <v-text-field
                  type="number"
                  :value="item.set.calculation.cost_cost_total | round(2)"
                  @change="changeProperty(item.part_uuid, 'cost_cost_total', Number($event))"
                  :style="item.set.calculation.cost_cost_total_modified ? 'background-color: aqua' : ''"
                  style="font-weight: bold"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td>
                <div class="d-flex justify-space-between">
                  <v-btn @click="expand(!isExpanded)" fab text small>
                    <v-icon v-if="!isExpanded">
                      expand_more
                    </v-icon>
                    <v-icon v-else>
                      expand_less
                    </v-icon>
                  </v-btn>
                  <v-btn @click="deletePart(item.part_uuid)" fab text small>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:group.header="{ headers, items, isOpen, toggle }">
            <th :colspan="headers.length - 2">
              <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
              {{ items[0].source_file_name }}
            </th>
            <th :colspan="2">{{ items.reduce((a, b) => a + b.set.calculation.total, 0) | round(2) }} CHF</th>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container>
                <v-row>
                  <template v-if="item.set.calculation.cost_has_error_during_calc">
                    <v-col cols="12" style="font-weight: bold">Fehler-Meldungen</v-col>
                    <v-col cols="12">
                      <v-list-item v-for="item in item.set.calculation.error_messages" :key="item">
                        <v-list-item-content>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </template>
                  <template v-if="item.set.calculation.has_filter_messages">
                    <v-col cols="12" style="font-weight: bold">Filter-Meldungen</v-col>
                    <v-col cols="12">
                      <v-list-item v-for="item in item.set.calculation.filter_messages" :key="item">
                        <v-list-item-content>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { BACKEND_URL } from '@/za_conf'
import GLTFViewer from '@/components/util/GLTFViewer'

export default {
  name: 'CalcParamPrototypeCost',
  data() {
    return {
      programmingItems: ['einfach', 'mittel', 'kompliziert'],
      materialItems: ['Stahl', 'Alu', 'CNS'],
      selectedHeaders: [],
      expanded: [],
      headersMap: {
        name: { text: 'Bezeichnung', align: 'start', sortable: false, value: 'name' },
        num_parts: { text: 'Anzahl', value: 'num_parts' },
        material: { text: 'Material', value: 'material' },
        share_operator: { text: 'Anteil Bediener', value: 'share_operator' },
        complexity_programming: { text: 'Komplexität Prog.', value: 'complexity_programming' },
        time_tube_laser_seconds: { text: 'Rohrlaser Zeit pro Stück [min]', value: 'cost_time_tube_laser_seconds' },
        tube_laser_cost_per_part: { text: 'Rohrlaserkosten pro Stück [CHF]', value: 'cost_tube_laser_cost_per_part' },
        tube_laser_cost_total: { text: 'Rohrlaserkosten Total [CHF]', value: 'cost_tube_laser_cost_total' },
        gas_cost_per_part: { text: 'Gaskosten pro Stück [CHF]', value: 'cost_gas_cost_per_part' },
        gas_cost_total: { text: 'Gaskosten Total [CHF]', value: 'cost_gas_cost_total' },
        time_operator_seconds: { text: 'Zeit Bediener per Stück [min]', value: 'cost_time_operator_seconds' },
        operator_cost_total: { text: 'Kosten Bediener Total [CHF]', value: 'cost_operator_cost_total' },
        time_programming_seconds: { text: 'Zeit Programmieren Total [min]', value: 'cost_time_programming_seconds' },
        programming_cost_total: { text: 'Kosten Programmieren Total [CHF]', value: 'cost_programming_cost_total' },
        cost_total_per_part: { text: 'Kosten pro Stück [CHF]', value: 'cost_cost_per_part' },
        cost_total: { text: 'Kosten Total [CHF]', value: 'cost_cost_total' },
        expand_slot: {
          text: 'Aktionen',
          value: 'data-table-expand',
          isAction: true,
          sortable: false,
        },
      },
      headers: [],
      addingThread: false,
    }
  },
  created() {
    this.headers = Object.values(this.headersMap)
    this.selectedHeaders = this.headers
    axios.post(`${BACKEND_URL}/get_user_preference`, { name: 'cost_headers' }).then(res => {
      if (res.data.cost_headers) {
        this.selectedHeaders = res.data.cost_headers
      }
    })
  },
  methods: {
    numSetChanged(count, setUUID, partUUID, ind) {
      this.$store.dispatch('numSetChanged', { count, setUUID, partUUID, ind })
    },
    changeHeaders(val) {
      axios.post(`${BACKEND_URL}/update_user_preference`, { name: 'cost_headers', value: val })
    },
    displayColumn(columnName) {
      return this.selectedHeaders.some(obj => obj.value === columnName)
    },
    deletePart(part_uuid) {
      this.$store.dispatch('deletePart', part_uuid)
    },
    validate(value, setUUID) {
      axios
        .post(`${BACKEND_URL}/set_validate`, {
          offer_id: this.$store.getters.currentOfferID,
          set_uuid: setUUID,
          value: Number(value),
        })
        .then(response => {
          this.$store.commit('addSetEntry', {
            setUUID: Object.keys(response.data).pop(),
            setEntry: Object.values(response.data).pop(),
          })
        })
    },
    changeProperty(part_uuid, name, value) {
      this.$store.dispatch('updatePartDetail', {
        part_uuid: part_uuid,
        name: name,
        value: value,
      })
    },
    nameOfFileUUID(uuid) {
      return this.$store.getters.getFileNameToFileUUID(uuid)
    },
    errorStyle(calculation) {
      let hasError = calculation.cost_has_error_during_calc
      let hasFilterMessages = calculation.has_filter_messages
      if (hasError) {
        return 'background-color: orange'
      } else if (hasFilterMessages) {
        return 'background-color: yellow'
      } else {
        return ''
      }
    },
    downloadExcel() {
      axios
        .post(
          `${BACKEND_URL}/download_params`,
          {
            offer_id: this.$store.getters.currentOfferID,
          },
          { responseType: 'blob' }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Offerte_${this.$store.getters.currentOfferID}_Parameter.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
    },
    downloadZip() {
      axios
        .post(
          `${BACKEND_URL}/download_step_files`,
          {
            offer_id: this.$store.getters.currentOfferID,
          },
          { responseType: 'blob' }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Offerte_${this.$store.getters.currentOfferID}_STEP.zip`)
          document.body.appendChild(link)
          link.click()
        })
    },
  },
  computed: {
    items: function() {
      return Object.values(this.$store.getters.parts).map(x => {
        x.set = this.$store.getters.getSingleSetOfPartUUID(x.part_uuid)
        return x
      })
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.some(x => x.value === s.value) || s.isAction)
    },
  },
  mounted() {},
}
</script>

<style>
.thread-detail input {
  padding: 10px;
  text-align: center;
}

.thread-detail-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.tt {
  border: 1px solid black;
}

table.tt {
  width: 80%;
  border-collapse: collapse;
  text-align: center;
}

table.tt td {
  border: 1px solid black;
}
</style>

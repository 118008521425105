
















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';
import CalcOfferItemInternal from '@/components/calculator/CalcOfferItemInternal.vue';
import { CustomerPartEntry } from '@/customer/part_details';
import CalcOfferItemExternal from '@/components/calculator/CalcOfferItemExternal.vue';
import ImageViewer from '@/components/util/ImageViewer.vue';
const Big = require('big.js');
let pdfMake = require('pdfmake/build/pdfmake.js');
let pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  components: {
    zaimagevisualizer: ImageViewer
  }
})
export default class CalcOffer extends Vue {
  dialog = false;
  dialogPDF = false;
  editedItem: any = {};
  BACKEND_URL = BACKEND_URL;
  total: number = 0;
  orderButtonsDisabled: boolean = false;
  headers: any = [
    {
      text: 'Teilename',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Bild',
      align: 'center',
      sortable: false,
      value: 'visualization_data'
    },
    { text: 'Material', value: 'material' },
    { text: 'Dicke', value: 'thickness' },
    { text: 'Finish', value: 'finish' },
    { text: 'Anzahl', value: 'count', align: 'right' },
    { text: 'Preis', value: 'price', align: 'right' }
  ];

  get anySelection() {
    return Object.values(this.$store.getters.currentOfferSelection).some(
      (t: any) => t.selectedIndex >= 0
    );
  }

  get selectedParts() {
    let selection = this.$store.getters.currentOfferSelection;
    let entries = [];
    this.total = 0;
    for (let key in selection) {
      let currentEntry = selection[key];
      let name = this.$store.getters.getFileNameForUUID(key);
      let currentPartInfo = this.$store.getters.getOfferByPartname(name);
      currentPartInfo = currentPartInfo[currentEntry.selectedIndex];
      if (currentEntry.selectedIndex >= 0) {
        this.total += currentPartInfo.cost_estimation.angebotspreis;
        entries.push({
          name: name,
          material: currentPartInfo.material,
          thickness: currentPartInfo.thickness,
          finish: currentPartInfo.finish,
          count: currentPartInfo.count,
          price: currentPartInfo.cost_estimation.angebotspreis,
          visualization_data: { uuid: key }
        });
      }
    }
    return entries;
  }

  showConfirmationDialog() {
    this.dialog = true;
  }

  commitOrder() {
    this.orderButtonsDisabled = true;
    let selection = this.$store.getters.currentOfferSelection;
    axios
      .post(`${BACKEND_URL}/create_order`, {
        selection: this.$store.getters.currentOfferSelection,
        offer_id: this.$store.getters.currentOfferID
      })
      .then(response => {
        this.orderButtonsDisabled = false;
        this.dialog = false;
        this.dialogPDF = true;
      });
  }
}

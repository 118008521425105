








import { Component, Prop, Vue } from 'vue-property-decorator';
import CalcShippingItem from '@/components/calculator/CalcShippingItem.vue';

@Component({
  components: {
    zaCalcShippingItem: CalcShippingItem
  }
})
export default class CalcShipping extends Vue {
  @Prop({ type: Object, required: true })
  parts!: any;
}

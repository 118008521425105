<template>
  <v-container>
    <v-row align="center">
      <v-col cols="6">
        <div class="text-h5">Angebot</div>
      </v-col>
      <v-col cols="6">
        <v-container>
          <v-row justify="end" no-gutters>
            <v-col cols="12" class="d-flex justify-end align-end">
              <v-btn @click="verify" v-if="!isVerified">Anfrage einreichen</v-btn>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0 d-flex justify-end align-end text-end">
              <orderdialog :offerId="offerId">
                <template v-slot:default="{ action }">
                  <v-btn @click="action">
                    <v-icon>shopping_cart</v-icon>
                    {{ buttonName(isVerified) }}
                  </v-btn>
                </template>
              </orderdialog>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12">
        <v-container>
          <v-row>
            <v-col v-if="!isVerified" cols="12">
              Leider war eine automatische Berechnung dieser Anfrage nicht möglich. Sie haben die Möglichkeit die
              Anfrage für eine manuelle Prüfung einzureichen oder direkt zu bestellen.
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="partsWithSets"
                item-key="name"
                disable-pagination
                hide-default-footer
                disable-sort
              >
                <template v-slot:item="{ item: { group_key, parts, sets } }">
                  <tr>
                    <td style="text-align: left; font-weight: bold">{{ getPartNameByUUID(parts[0]) }}</td>
                    <template v-for="(setUUID, index) in sets">
                      <td :key="`${group_key}td_los${index}`">{{ getCountOfSetForPart(parts[0], setUUID) }}</td>
                      <td
                        :key="`${group_key}td_los_cost${index}`"
                        v-if="getSetByUUID(setUUID).calculation.total_correct"
                      >
                        {{ getSetByUUID(setUUID).calculation.total_correct }} CHF
                      </td>
                      <td :key="`${group_key}td_los_cost${index}`" v-else>
                        manuelle Prüfung
                      </td>
                    </template>
                    <td
                      colspan="2"
                      v-for="(_, index) in maxNumberOfSetsInGroup - sets.length"
                      :key="`${group_key}td_los_filler${index}`"
                    />
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { BACKEND_URL } from "@/za_conf";
import Vue from "vue";
import axios from "axios";
import CalcOrderDialog from "@/components/calculator/CalcOrderDialog.vue";

export default Vue.extend({
  components: {
    orderdialog: CalcOrderDialog
  },
  // type inference enabled
  data () {
    let vm = this;
    return {
      BACKEND_URL: BACKEND_URL,
      offeredPrices: []
    };
  },

  methods: {
    getPartByUUID (partUUID) {
      return this.$store.getters.parts[partUUID];
    },
    getPartNameByUUID (partUUID) {
      let fileName = this.$store.getters.parts[partUUID].source_file_name;
      let partName = this.$store.getters.parts[partUUID].details.name;
      return `${fileName} - ${partName}`;
    },
    getSetByUUID (uuid) {
      return this.$store.getters.sets[uuid];
    },
    getCountOfSetForPart (partUUID, setUUID) {
      let currSet = this.$store.getters.sets[setUUID];
      for (let entry of currSet.parts) {
        if (entry.part_uuid == partUUID) {
          return entry.count;
        }
      }
      return -1;
    },
    verify () {
      axios.post(`${BACKEND_URL}/verify`, {
        offer_id: this.offerId
      }).then(response => {
        this.$router.push("/");
      });
    },
    buttonName (isVerfied) {
      if (isVerfied) {
        return "Bestellen";
      } else {
        return "Direkt bestellen";
      }
    }

  },
  computed: {
    headers() {
      let headers = [
        {
          text: 'Teil',
          align: 'left',
          sortable: false
        }
      ];
      for (let i = 0; i < this.maxNumberOfSetsInGroup; i++) {
        headers.push({
          text: `Losgrösse ${i + 1}`,
          align: 'right',
          sortable: false
        });
        headers.push({
          text: `Kosten Losgrösse ${i + 1}`,
          align: 'right',
          sortable: false
        });
      }

      return headers;
    },
    isVerified () {
      let setsVerified = this.partsWithSets.map(x => x.sets).flat().
        map(y => this.getSetByUUID(y)).
        map(z => z.calculation.is_verified);
      return (this.$store.getters.getCurrentOfferStatus === "Entwurf" && setsVerified.every(Boolean)) ||
        this.$store.getters.getCurrentOfferStatus === "Verifiziert";
    },
    partsWithSets () {
      return Object.values(this.$store.getters.getSetGroups);
    },
    offerId () {
      return this.$store.getters.currentOfferID;
    },
    maxNumberOfSetsInGroup () {
      let maxNumberOfSetsInGroup = Math.max(
        ...Object.values(this.$store.getters.getSetGroups).map((x) => {
          return x.sets.length;
        }),
        0
      );
      return maxNumberOfSetsInGroup;
    }
  },
  destroyed () {}
});
</script>
<style>
tr.tbdetails:hover {
  background-color: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: right;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  text-align: right;
}
</style>

export interface AdditionalPartFile {
  name: string;
  uuid: string;
  size: number;
  type: string;
  progress: number;
}

interface PartDetails {
  name: string; // server
  uuid: string; // server
  progress: number; // server
  has_image: boolean; // server
  counts: number[]; // client
  single_position: boolean;

  part_details: Object;
  set_part_details: Object[];
  additional_part_files: AdditionalPartFile[];
}

export abstract class PartEntry implements PartDetails {
  name: string = ''; // server
  uuid: string = ''; // server
  progress: number = 0; // server
  has_image: boolean = false; // server
  counts: number[] = [1, 0, 0]; // client
  single_position: boolean = false;
  layerDialog: boolean = false; // server //TODO
  type: string = '2D'; // TODO SERVER

  part_details: Object = {};
  set_part_details: Object[] = [];
  additional_part_files: AdditionalPartFile[] = [];

  abstract displayProperties(): DisplayProperty[];

  protected abstract createPartDetailObject(): Object;

  createUpdateObject(): Object {
    return {
      uuid: this.uuid,
      single_position: this.single_position,
      counts: this.counts,
      set_part_detail: this.createPartDetailObject()
    };
  }

  setFieldsFromObject(part_details: PartDetails) {
    this.name = part_details.name;
    this.uuid = part_details.uuid;
    this.progress = part_details.progress;
    this.has_image = part_details.has_image;
    this.counts = part_details.counts;
    this.additional_part_files = part_details.additional_part_files;

    this.setFieldsFromDetailsObject(part_details.part_details);

    if (Object.keys(part_details.set_part_details[0]).length !== 0) {
      this.setFieldFromPositionDetailsObject(
        part_details.set_part_details[0]
      );
    }
  }

  abstract setFieldsFromDetailsObject(part_details: Object): void;

  abstract setFieldFromPositionDetailsObject(position_details: Object): void;
}

export interface DisplayProperty {
  displayName: string;
  displayValue: string;
  displaySuffix?: string;
}



















import { Component, Prop, Vue } from 'vue-property-decorator'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import { isMobile } from '@/main'

@Component({
  components: {
    navdraw: NavigationDrawer,
  },
})
export default class Header extends Vue {
  isMobile() {
    return isMobile()
  }

  logout() {
    this.$store.commit('logoutUser')
    this.$router.push('/login')
  }

  get loggedIn() {
    return this.$store.getters.isAuthenticated
  }
}
